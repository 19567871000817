import { lazy, Suspense } from 'react';
import {Routes,Route} from 'react-router-dom'
import Navbar from './components/Navbar'
import Home from './pages/Home'
import Footer from './components/Footer'
import About from './pages/About'
import Contact from './pages/Contact'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import Loading from './components/Loading';
// import AllProducts from './pages/AllProducts'
import AllProductsTemplate from './pages/allproducts';
import PackagePrinting from './pages/allproducts/packagingprinting';
import Product from './pages/product';
import TopPaginatiom from './components/breadcrum/TopPagination';
import {SkeletonTheme} from 'react-loading-skeleton'
import ForgotS from './authentication/ForgotS';
import SearchProducts from './pages/SearchProducts';
import GooglePage from './components/GooglePage';
import RazorpaySimulator from './pages/RazorpaySimulator';


const HomeScreen = lazy(()=>import('./pages/Home'))
const Blogs = lazy(()=>import("./pages/blogs/Blogs"))
const BlogScreen = lazy(() => import('./pages/blogs/BlogScreen'));
const MainSideBar = lazy(() => import('./pages/myaccount/MainSideBar'));
const CartScreen = lazy(() => import('./pages/cartscreen/CartMain'))
const FAQ = lazy(()=> import("./pages/faq/Faq"))
const Return  = lazy(()=>import("./pages/return/Return"))
const PaymentSuccess = lazy(()=>import("./pages/PaymentSuccess"))






const App = () => {
  return (
    <div className='font-lato'>

      <Navbar/>
      {/* <TopPaginatiom/> */}
      <SkeletonTheme baseColor='#D9D9D9' highlightColor='#b9b8b8'>

      <Routes>
      <Route path='/' element={<Suspense fallback={<Loading/>}><HomeScreen /></Suspense>} />

        <Route path='/aboutus' element={<About />} />
        <Route path='/contactus' element={<Contact />} />
        <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        <Route path='/termsandconditions' element={<TermsAndConditions />} />
        <Route path='/AllBlogs' element={<Suspense fallback={<Loading/>}><Blogs /></Suspense>} />
        <Route path='/AllBlogs/Blog/:id' element={<Suspense fallback={<Loading/>}><BlogScreen /></Suspense>} />
        <Route path='/myaccountDetail' element={<Suspense fallback={<Loading/>}><MainSideBar /></Suspense>} />
        <Route path='/allproducts' element={<AllProductsTemplate/>} />
        <Route path='/allproducts/:category' element={<AllProductsTemplate/>} />
        <Route path='/allproducts/industry/:industry' element={<AllProductsTemplate/>} />

        <Route path='/searchProduct' element={<SearchProducts/>}/>
        <Route path='/allproducts/packagingprinting/:slug' element={<PackagePrinting/>} />
        {/* <Route path='/allproducts/packagingprinting/boxpackaging/:slug' element={<Product/>} /> */}
        <Route path='/:id' element={<Product/>} />
        <Route path='/account/cart' element={<Suspense fallback={<Loading/>}><CartScreen /></Suspense>} />
        <Route path='/FAQs' element={<Suspense fallback={<Loading/>}><FAQ /></Suspense>} />
        <Route path='/return' element={<Suspense fallback={<Loading/>}><Return /></Suspense>} />
        <Route path="/razorpay-simulator" element={<RazorpaySimulator />} />
        <Route path='/payment/:token' element={<Suspense fallback={<Loading/>}><PaymentSuccess /></Suspense>} />
        
        <Route path='/google-signin/callback' element={<Suspense fallback={<Loading/>}><GooglePage /></Suspense>} />

      </Routes>
      </SkeletonTheme>
      <Footer />
  
    </div>
  );
}

export default App;
