import React, { useEffect, useRef, useState, Suspense, lazy } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import { Link, useLocation } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import { motion } from "framer-motion";
import { MyContext } from "../context/Forgot";
import { useContext } from "react";
import queryString from "query-string";
import Company from "../assets/img/companys.png";
import Company2 from "../assets/img/company2.png";
import Company1 from "../assets/img/company1.png";
import {
  useHomeBannerImageQuery,
  useHomePageSliderProductQuery,
  usePartnerLogosQuery,
} from "../redux/productrtk/Product";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet-async";

const FindYourFit = lazy(() => import("../components/FindYourFit"));
const HappyClients = lazy(() => import("../components/HappyClients"));
const ProductImages = lazy(() => import("./ProductImages"));
const WhyInkPrintNew = lazy(() => import("../components/WhyInkPrintNew"));
const ForgotModel = lazy(() => import("../authentication/ForgotModel"));
const ForgotS = lazy(() => import("../authentication/ForgotS"));

const loadSwiper = () => import("swiper/css");
const loadSwiperPagination = () => import("swiper/css/pagination");
const loadSwiperNavigation = () => import("swiper/css/navigation");
const loadAOS = () => import("aos/dist/aos.css");


const Home = () => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const { value, setValue } = useContext(MyContext);
  const { data: packageprinting, isLoading } = useHomePageSliderProductQuery();
  const [metaTags, setMetaTags] = useState(null);
  const { data: partnerlogo, isLoading: isloading2 } = usePartnerLogosQuery();
  const { data: homeBanner } = useHomeBannerImageQuery();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const swiperRefs = useRef([]);
  const location = useLocation();
  const LOCAL_STORAGE_KEY = "currentPage";
  const { forgotPass, forgotToken } = queryString.parse(location.search);
  useEffect(() => {
    sessionStorage.removeItem(LOCAL_STORAGE_KEY);
  }, []); 
  useEffect(() => {
    const fetchMetaTags = async () => {
      try {
        const response = await fetch("https://api.inkprint.in/api/contact_us/home-meta-tags/");
        if (response.ok) {
          const jsonData = await response.json();
          console.log("Home meta tags:", jsonData);
          setMetaTags(jsonData.meta_tags);
        } else {
          console.error("Failed to fetch home meta tags");
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchMetaTags();
    // dynamically load CSS in useeffect for swiper
    Promise.all([
      loadSwiper(),
      loadSwiperPagination(),
      loadSwiperNavigation(),
      loadAOS(),
    ]).then(() => {
      import("aos").then((AOS) => {
        AOS.init({
          duration: 500,
          once: true, 
        });
      });
    });

    window.scrollTo(0, 0);
  }, []);


  return (
    <>
    {metaTags && (
        <Helmet>
          <title>{metaTags.title}</title>
          <meta name="description" content={metaTags.description} />
          <meta name="keywords" content={metaTags.keywords} />
          <meta name="robots" content={metaTags.robots} />
          <link rel="canonical" href={metaTags.canonical_url} />
          <meta property="og:title" content={metaTags.og_title} />
          <meta property="og:description" content={metaTags.og_description} />
          <meta property="og:image" content={metaTags.og_image} />
          <meta property="og:url" content={metaTags.og_url} />
        </Helmet>
      )}
      
      {value === true && (
        <Suspense fallback={<Loading />}>
          <div className="absolute z-[999]">
            <ForgotModel setValue={setValue} />
          </div>
        </Suspense>
      )}

      {forgotPass === "true" && forgotToken && (
        <Suspense fallback={<Loading />}>
          <div className="absolute z-[999]">
            <ForgotS setValue={setValue} token={forgotToken} />
          </div>
        </Suspense>
      )}

      <div className="hero1 w-full mt-[125px] md:mt-10 container_maxWidth_1440">
        <div className="flex justify-between items-center mx-[60px] md:mx-[12px] md:block md:flex-col md:items-center">
          <div className="hidden md:block md:mt-[-30px] w-full">
            <img
              alt=""
              src={homeBanner?.image}
              className="min-w-full"
              loading="lazy"
              width="500"
              height="700"
            />
          </div>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="hero-text w-1/2 md:w-full ml-[-10px] md:ml-[5px]"
          >
            <h1 className="text-[36px] font-semibold cursor-auto max-w-[643px] leading-[50px] md:leading-[24px] text-custom-text-p ll:text-xl md:mt-[24px] md:text-[22px]">
              Elevate Your Brand with InkPrint: Unleash the Power of{" "}
              <span className="text-custom-text-hero cursor-auto">
                Exceptional Packaging Solutions and Design Expertise.
              </span>
            </h1>
            <p className="text-[#2F2F2F] font-normal leading-8 max-w-[643px] text-[24px] pt-10 ll:pt-3 md:pt-[6px] md:text-[14px] md:leading-[17px] font-lato cursor-auto">
              Your Brand, Our Box: Crafting Packaging Magic with
              <br /> InkPrint.
            </p>

            <div className="mt-[119px] md:mt-[12px] w-[80px]">
              <Link to="/allproducts">
                <button className="relative w-[200px] md:w-[109px] h-[40px] md:h-[35px] bg-custom-btn flex items-center justify-center text-white text-[20px] md:text-[12px] rounded-[4px] hover:bg-custom-btn-dark transition duration-300">
                  Explore more <FiArrowUpRight className="ml-2 md:ml-1" />
                </button>
              </Link>
            </div>
            <div className="trusted_company mt-[24px] md:mt-[12px] md:mb-[22px]">
  <p className="text-[16px] md:mt-4 md:text-[15px] font-lato text-[#2F2F2F] font-[500]">
    Trusted by Companies like:
  </p>

  
  <img
    alt="Trusted Company"
    src={Company}
    className="w-[500px] mt-1 ml-[-2px] md:h-[40px] md:w-[370px] sm:hidden" 
    loading="lazy"
    width="500"
    height="auto"
  />

  
  <div className="hidden sm:block">
    <img
      alt="Trusted Company 1"
      src={Company1}
      className="w-[280px] h-auto mt-2 ml-0"
      loading="lazy"
    />
    <img
      alt="Trusted Company 2"
      src={Company2}
      className="w-[280px] h-auto mt-2 ml-[-1px]"
      loading="lazy"
    />
  </div>
</div>

          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="banner_image w-1/2 md:hidden"
          >
            <img
              alt="Hero Banner"
              src={homeBanner?.image}
              className="w-[691px] mt-[-30px] object-cover h-[618px]"
              loading="lazy"
              width="691"
              height="618"
            />
          </motion.div>
        </div>
      </div>

      <Suspense fallback={<Loading />}>
        {packageprinting?.results?.map((parent, index) => (
          <ProductImages key={index} parent={parent} index={index} />
        ))}

        <WhyInkPrintNew />

        <div className="partners mx-[60px] pb-5 md:mx-[12px] mt-[100px] md:mt-[-10px] md:mb-[30px]">
          <div className="ourpartner flex items-center">
            <h1
              className="text-[#000000] text-[32px] font-[500] font-lato md:text-[24px] md:font-bold"
              data-aos="fade-right"
            >
              Our Partners
            </h1>
          </div>
          <div
            className="partners-images flex justify-between items-center mt-[20px] mb-8 md:mt-[12px]"
            data-aos="fade-up"
          >
            <Swiper
              slidesPerView={window.innerWidth <= 500 ? 3 : 3.3}
              spaceBetween={10}
              navigation={{
                nextEl: ".swiper_button_next",
                prevEl: ".swiper_button_prev",
              }}
              pagination={false}
              keyboard={true}
              autoplay={true}
              mousewheel={{ forceToAxis: true, thresholdDelta: 30 }}
              onSlideChange={(swiper) => setSliderIndex(swiper.realIndex)}
              modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
              breakpoints={{
                500: {
                  slidesPerView: 3.5,
                },
                768: {
                  slidesPerView: 4,
                },
                1024: {
                  slidesPerView: 4.3,
                },
                1270: {
                  slidesPerView: 5.3,
                }
              }}
              className="mySwiper"
            >
              {partnerlogo?.results?.map((logo, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={logo?.logo}
                    alt="Partner logo"
                    className="partner_image h-[90px] w-[120px] md:h-[45px] md:w-[50px] object-contain"
                    loading="lazy"
                    width="120"
                    height="90"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <FindYourFit />
        <HappyClients />
      </Suspense>
    </>
  );
};

export default Home;